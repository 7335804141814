<template>
    <div class="scrolllayout"  @click="handleswiperchoose">
        <div :class="swiper1[0].swiperclass==0?'noswiper':'swiper'" :style="'z-index:'+swiper1[0].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[0].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow">
                <div class="shadow-swiper1-top">
                    <div class="shadow-swiper1-top-top">
                        <div class="shadow-swiper1-top-top-top">
                        <span class="wy">
                            欢迎来到
                        </span>
                        </div>
                        <div class="shadow-swiper1-top-top-bottom">
                        <span class="wy">
                            鑫嵘科技资讯中心
                        </span>
                        </div>
                    </div>
                    <div class="shadow-swiper1-top-bottom" :style="'opacity:'+textstyle.opacity">
                        <div class="shadow-swiper1-top-bottom-top">
                            {{dataobj.datalist[datachoose].title}}
                        </div>
                        <div class="shadow-swiper1-top-bottom-bottom">
                            {{dataobj.datalist[datachoose].content1}}丨{{dataobj.datalist[datachoose].content2}}
                        </div>
                    </div>
                    <div class="shadow-swiper1-top-bottom1" >
                        <div class="shadow-swiper1-top-bottom1-left"  @click.stop  @mousewheel.stop>
                            <div class="shadow-swiper1-top-bottom1-left-left" :style="componentData==1024?'width:515.94px;height:300px;cursor: pointer':'cursor: pointer'">
                                <div class="shadow1" :style="componentData==1024?'width:515.94px;height:300px;z-index: '+(videostyle.imgindexz+1)+';opacity:'+videostyle.opacity:'z-index: '+(videostyle.imgindexz+1)+';opacity:'+videostyle.opacity" @click="handlevideostyle()">
                                    <div class="shadow-swiper1-top-bottom1-left-left-text">
                                        {{dataobj.datalist[datachoose].content1}}
                                    </div>
                                    <div class="triangle" :style="'transform:'+videostyle.transform"></div>
                                </div>
                                <img class="video" :src="dataobj.datalist[datachoose].img" :style="componentData==1024?'width:515.94px;height:300px;opacity:'+videostyle.opacity+';z-index:'+videostyle.imgindexz:'opacity:'+videostyle.opacity+';z-index:'+videostyle.imgindexz" alt="">
                                <video class="video" ref="myvideo" controls :src="dataobj.datalist[datachoose].video" :style="componentData==1024?'width:515.94px;height:300px;z-index:'+videostyle.videoindexz+';opacity:'+(videostyle.videoopacity):'z-index:'+videostyle.videoindexz+';opacity:'+(videostyle.videoopacity)"></video>
                            </div>
                            <div class="shadow-swiper1-top-bottom1-left-right" :style="componentData==1024?'height:300px':''" @click="fullscreen">
                                <div class="shadow-swiper1-top-bottom1-left-right-top">
                                    <div class="shadow-swiper1-top-bottom1-left-right-top-top">
                                        <div class="shadow-swiper1-top-bottom1-left-right-top-top-left">
                                            {{dataobj.title}}
                                        </div>
                                        <div class="shadow-swiper1-top-bottom1-left-right-top-top-right">
                                            <div class="shadow-swiper1-top-bottom1-left-right-top-top-right-left">{{'>'}}</div>
                                            <div class="shadow-swiper1-top-bottom1-left-right-top-top-right-right">{{'>'}}</div>
                                        </div>
                                    </div>
                                    <div class="shadow-swiper1-top-bottom1-left-right-top-bottom">
                                        {{dataobj.content}}
                                    </div>
                                </div>

                                <!--                                <div class="shadow-swiper1-top-bottom1-left-right-list" :style="componentData==1024?'height:170px':''">-->
                                <!--                                    <div class="shadow-swiper1-top-bottom1-left-right-list-unit" v-for="(v,i) in dataobj.datalist" :key="v.id" @click="handledatachoose(i)">-->
                                <!--                                        <div>-->
                                <!--                                            <img :src="v.img" alt="">-->
                                <!--                                        </div>-->
                                <!--                                        <div>-->
                                <!--                                            <div>{{v.title}}</div>-->
                                <!--                                            <div>{{v.content1}} | {{v.content2}}</div>-->
                                <!--                                            <div>{{v.date}}</div>-->
                                <!--                                        </div>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->


                                <div class="shadow-swiper1-top-bottom1-left-right-list" :style="componentData==1024?'height:170px;':''">
                                    <div class="shadow-swiper1-top-bottom1-left-right-list-unit" v-for="(v,i) in dataobj.datalist" :key="v.id" @click="handledatachoose(i)">
                                        <div class="shadow-swiper1-top-bottom1-left-right-list-unit-left">
                                            <img :src="v.img" alt="">
                                        </div>
                                        <div class="shadow-swiper1-top-bottom1-left-right-list-unit-right">
                                            <div class="shadow-swiper1-top-bottom1-left-right-list-unit-right-title">{{v.title}}</div>
                                            <div class="shadow-swiper1-top-bottom1-left-right-list-unit-right-content">{{v.content1}} | {{v.content2}}</div>
                                            <div class="shadow-swiper1-top-bottom1-left-right-list-unit-right-date">{{v.date}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="shadow-swiper1-top-bottom1-right">
                            <div class="shadow-swiper1-top-bottom1-right-left">
                                或许会有点意思
                            </div>
                            <div class="shadow-swiper1-top-bottom1-right-right">
                                看看我们都在做什么事
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import s1 from '../img/n1.jpg'
    import n2 from "../img/n2.png";
    import n3 from "../img/n3.png";
    import n4 from "../img/n4.png";
    import n5 from "../img/n5.png";
    import video from "../img/home.mp4"


    export default {
        data(){
            return{
                //轮播到哪一页，0为第一页，1为第二页，2为第三页，3为第四页，-1为跳转上一页路由，4为跳转下一页路由
                swiperchoose: 0,
                //记录变化前的swiperchoose
                oddswiperchoose:0,


                //界面宽度的一半
                resizeX:0,


                //节流，阻止事件
                preventcontact:0,


                //轮播
                swiper1: [
                    {
                        //背景图
                        bac: s1,
                        //高度
                        indexz:99,
                        //判断左移还是右移的class,1为左移，-1为右移，0为进入页面不动的默认情况
                        swiperclass:0,
                        diybarposition:1,
                    },
                ],
                videostyle:{
                    transform:'translateX(-50%) translateY(-50%) scale(1)',
                    opacity:1,
                    videoopacity:0,
                    imgindexz:99,
                    videoindexz:0,
                },

                textstyle:{
                    opacity:1,
                },

                datachoose:0,
                dataobj:{
                    title:'看见丨第一季',
                    content:'鑫嵘科技·与你一起洞见下个十年',
                    datalist:[
                        {
                            id:1,
                            img:n2,
                            video:video,
                            title:'私域公开课',
                            content1:'星物种观察室',
                            content2:'为企业讲解如何搭建品牌的私域矩阵案例',
                            date:'2021-8-8',
                        },
                        {
                            id:2,
                            img:n3,
                            video:video,
                            title:'私域公开课',
                            content1:'星物种观察室',
                            content2:'为企业讲解如何搭建品牌的私域矩阵案例为企业讲解如何搭建品牌的私域矩阵案例为企业讲解如何搭建品牌的私域矩阵案例为企业讲解如何搭建品牌的私域矩阵案例',
                            date:'2021-8-8',
                        },
                        {
                            id:3,
                            img:n4,
                            video:video,
                            title:'私域公开课',
                            content1:'星物种观察室',
                            content2:'为企业讲解如何搭建品',
                            date:'2021-8-8',
                        },
                        {
                            id:4,
                            img:n5,
                            video:video,
                            title:'私域公开课',
                            content1:'星物种观察室',
                            content2:'为企业讲解如何搭建品牌的私域矩阵案例',
                            date:'2021-8-8',
                        },
                        {
                            id:5,
                            img:n5,
                            video:video,
                            title:'私域公开课',
                            content1:'星物种观察室',
                            content2:'为企业讲解如何搭建品牌的私域矩阵案例',
                            date:'2021-8-8',
                        },
                    ],
                },
            }
        },

        props:{
            componentData:{
                default:0,
                type:Number,
            }
        },
        created() {
            //监听鼠标滚动，500毫秒目的是为了等上一次滚动结束
            setTimeout(()=>{
                window.addEventListener('mousewheel',this.handlemouse)
            },500)
            //监听网页宽度
            window.addEventListener('resize',this.resize)
            this.resize()

            //监听按键
            setTimeout(()=>{
                window.addEventListener('keydown',this.keydown)
            },500)
        },
        methods: {
          //全屏
          fullscreen() {
            this.isFullScreen = true
            //全屏
            var el = document.documentElement
            var rfs = el.requestFullScreen||el.webkitRequestFullScreen||el.mozRequestFullScreen||el.msRequestFullScreen
            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
            console.log(rfs)
            if(!isFullScreen){
              if(rfs){
                rfs.call(el)
              }
            }
          },
            //点击视频菜单事件
            handledatachoose(e){
              //监听是否全屏
              let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
              if(!isFullScreen){
                return;
              }
                if(this.datachoose==e){
                    return
                }
                this.videostyle.opacity= 0.2
                this.videostyle.videoopacity = 0
                this.videostyle.transform = 'translateX(-50%) translateY(-50%) scale(0)'
                this.videostyle.imgindexz = 0
                this.textstyle.opacity = 0.2
                this.videostyle.videoindexz = 0
                setTimeout(()=>{
                    this.datachoose=e
                    this.videostyle.opacity= 1
                    this.videostyle.transform = 'translateX(-50%) translateY(-50%) scale(1)'
                    this.videostyle.imgindexz = 99
                    this.textstyle.opacity = 1
                    this.$refs.myvideo.currentTime = 0
                },500)
            },
            //点击视频播放按钮
            handlevideostyle(){
                this.videostyle.opacity= 0
                this.videostyle.transform = 'translateX(-50%) translateY(-50%) scale(2)'
                this.videostyle.imgindexz = 0
                this.videostyle.videoindexz = 99
                this.videostyle.videoopacity = 1
                this.$nextTick(()=>{
                    setTimeout(()=>{
                        this.$refs.myvideo.play()
                    },300)
                })
            },
            //点击鼠标时触发,点击左边界面右移下一页，点击右边界面左移上一页
            handleswiperchoose(e) {
              //监听是否全屏
              let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
              if(!isFullScreen){
                return;
              }
                if(this.preventcontact){
                    return
                }
                this.preventcontact=1
                let X = e.clientX
                // if(this.componentData){
                //     X = e.clientY
                // }
                if(X>=this.resizeX){
                    this.swiperchoose++
                }else{
                    this.swiperchoose--
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            handlediybar(e){
                if(e.target==this.swiperchoose){
                    return
                }
                this.swiperchoose=e.target
                this.diybarData.targetleft = 25 + 50*e.target
            },
            //监听鼠标滚动事件,上滚回退上一张，下滚继续下一张
            handlemouse(e){

                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.deltaY>0){
                    this.swiperchoose++

                }else{

                    this.swiperchoose--


                }

                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            resize(){
                if(this.componentData){
                    this.resizeX = this.componentData/2
                    return
                }
                this.resizeX = document.body.clientWidth/2
            },
            //跳转路由
            handlerouter(e){
                if(window.location.pathname==e){
                    return
                }
                this.$router.push(e)
            },
            //监听键盘
            keydown(e){
                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.keyCode==39){
                    this.swiperchoose++
                }else if(e.keyCode==37){
                    this.swiperchoose--
                }else if(e.keyCode==38){
                    this.swiperchoose=-1
                }else if(e.keyCode==40){
                    this.swiperchoose=4
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            }
        },

        watch:{
            swiperchoose:function (e) {
                if(e>0){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index+1].router)
                    return
                }else if(e<0){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index-1].router)
                    return;
                }
                // if(e==0){
                //     this.swiper1[0].indexz=99
                //     this.swiper1[2].indexz=-1
                //     this.swiper1[3].indexz=-1
                //     this.swiper1[1].indexz=-1
                //     if(this.oddswiperchoose>=1){
                //         this.swiper1[0].swiperclass= -1
                //     }
                //     this.swiper1[this.oddswiperchoose].indexz=1
                //     this.swiper1[1].swiperclass= 0
                //     this.swiper1[2].swiperclass= 0
                //     this.swiper1[3].swiperclass= 0
                //
                //
                //
                // }else if(e==1){
                //     this.swiper1[1].indexz=99
                //     this.swiper1[3].indexz=-1
                //     this.swiper1[0].indexz=-1
                //     this.swiper1[2].indexz=-1
                //     if(this.oddswiperchoose>=2){
                //         this.swiper1[1].swiperclass= -1
                //     }else{
                //         this.swiper1[1].swiperclass= 1
                //     }
                //     this.swiper1[this.oddswiperchoose].indexz=1
                //     this.swiper1[0].swiperclass= 0
                //     this.swiper1[2].swiperclass= 0
                //     this.swiper1[3].swiperclass= 0
                // }else if(e==2){
                //     this.swiper1[0].indexz=-1
                //     this.swiper1[2].indexz=99
                //     this.swiper1[1].indexz=-1
                //     this.swiper1[3].indexz=-1
                //     if(this.oddswiperchoose>=3){
                //         this.swiper1[2].swiperclass= -1
                //
                //     }else{
                //         this.swiper1[2].swiperclass= 1
                //     }
                //     this.swiper1[this.oddswiperchoose].indexz=1
                //     this.swiper1[0].swiperclass= 0
                //     this.swiper1[1].swiperclass= 0
                //     this.swiper1[3].swiperclass= 0
                // }else if(e==3){
                //     this.swiper1[0].indexz=-1
                //     this.swiper1[1].indexz=-1
                //     this.swiper1[3].indexz=99
                //     this.swiper1[2].indexz=-1
                //     if(this.oddswiperchoose<=2){
                //         this.swiper1[3].swiperclass= 1
                //     }
                //     this.swiper1[this.oddswiperchoose].indexz=1
                //     this.swiper1[0].swiperclass= 0
                //     this.swiper1[1].swiperclass= 0
                //     this.swiper1[2].swiperclass= 0
                // }
                // this.oddswiperchoose = e
                // this.diybarData.targetleft= 25+50*e
                // this.diybarData.position = this.swiper1[e].diybarposition
            }
        },
    }
</script>

<style lang="scss" scoped>

    .swiper{
        position: absolute;
        left: -100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnright;
    }
    @keyframes turnright {
        0% {
            left: -100%;
        }
        100% {
            left: 0;
        }
    }
    .reswiper{
        position: absolute;
        left: 100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnleft;
    }
    @keyframes turnleft {
        0% {
            left: 100%;
        }
        100% {
            left: 0;
        }
    }

    .noswiper{
        position: absolute;
        left:0%;
        width: 100%;
        height: 100%;
    }



    .shadow{
        background: rgba(0, 0, 0, 0.5);
    }
    .shadow-swiper1-top{
        position: absolute;
        top: 22.1%;
        left: 5.1%;
        right: 0;
      @media screen and (max-width: 1400px) {
        top: 18%;
      }
    }
    .shadow-swiper1-top-bottom{
        margin-top: 31px;
        transition: 0.5s;
    }
    .shadow-swiper1-top-bottom-top{
        margin-bottom: 8px;
        font-size: var(--s-t-size);
    }
    .shadow-swiper1-top-bottom-bottom{
        font-size: var(--s-c-size);
    }
    .shadow-swiper1-top-bottom1{
        margin-top: 12px;
        margin-right: 7.1%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
    .shadow-swiper1-top-bottom1-left{
        /*width: 1219px;*/
        /*height: 433px;*/
        /*min-width: 1219px;*/
        /*min-height: 433px;*/
        display: flex;
        flex-direction: row;
        margin-right: 40px;
        transition: 0.5s;
        width: 1284.48px;
        @media screen and (max-width: 1540px) {
            width: 992.5px;
        }
    }
    .shadow-swiper1-top-bottom1-left-left{
        width: 859.9px;
        height: 500px;
        position: relative;
        @media screen and (max-width: 1540px) {
            height:400px;
            width: 687.92px;
        }
    }
    .shadow-swiper1-top-bottom1-left-left-text{
        position: absolute;
        left: 11px;
        bottom: 11px;
        right: 0;
        font-size: var(--s-t-size);
    }
    .shadow-swiper1-top-bottom1-left-right{
        height: 500px;
        background-image: linear-gradient(to right,rgba(0,58,102,0.9),rgba(0,58,102,0.6));
        transition: 0.5s;
        @media screen and (max-width: 1540px) {
            height:400px;
        }
    }
    .shadow-swiper1-top-bottom1-left-right-top{
        font-size: 16px;
        padding-top: 33px;
        padding-left: 20px;
        padding-right: 26px;
        display: flex;
        flex-direction: column;
    }
    .shadow-swiper1-top-bottom1-left-right-top-top{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .shadow-swiper1-top-bottom1-left-right-top-top-right{
        font-size: 20px;
        display: flex;
        flex-direction: row;
    }
    .shadow-swiper1-top-bottom1-left-right-top-top-right-left{
        margin-right: 29px;
        transform: rotateZ(90deg);
        cursor: pointer;
    }
    .shadow-swiper1-top-bottom1-left-right-top-top-right-right{
        transform: rotateZ(-90deg);
        cursor: pointer;
    }
    .shadow-swiper1-top-bottom1-left-right-top-bottom{
        font-size: 11px;
        margin-top: 5px;
        margin-bottom: 32px;
    }
    .shadow-swiper1-top-bottom1-left-right-list{
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        height: 360px;
        transition: 0.5s;
        overflow-y: scroll;
       -webkit-overflow-scrolling: touch;
        @media screen and (max-width: 1540px) {
            height:260px;
        }
    }

    .shadow-swiper1-top-bottom1-left-right-list-unit{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        cursor: pointer;
        flex: none;
    }
    .shadow-swiper1-top-bottom1-left-right-list-unit:first-of-type{
        margin-top: 0;
    }
    .shadow-swiper1-top-bottom1-left-right-list-unit-left{
        width: 137.6px;
        height: 80px;
        min-height: 80px;
        min-width: 137.6px;
        margin-right: 9px;
    }
    .shadow-swiper1-top-bottom1-left-right-list-unit-right-title{
        font-size: var(--s-c-size);
        margin-bottom: 2px;
    }
    .shadow-swiper1-top-bottom1-left-right-list-unit-right-content{
        font-size: 9px;
        width: 230px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.5;
        height: 38px;
        transition: 0.5s;
        @media screen and (max-width: 1540px) {
            width:110px;
        }
    }
    .shadow-swiper1-top-bottom1-left-right-list-unit-right-date{
        font-size: 9px;
        text-align: right;
        line-height: 1.5;
    }
    .shadow-swiper1-top-bottom1-right{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        font-size: 28px;
    }
    .shadow-swiper1-top-bottom1-right-left,.shadow-swiper1-top-bottom1-right-right{
        writing-mode: vertical-lr;/*从左向右 从右向左是 writing-mode: vertical-rl;*/
        writing-mode: tb-lr;/*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
        width: 35px;
    }
    .shadow-swiper1-top-bottom1-right-left{
        margin-right: 17px;
    }
    .triangle {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 0;
        border-top: 25px solid transparent;
        border-left: 37.5px solid white;
        border-bottom: 25px solid transparent;
        transition: 0.5s;
    }
    .shadow1{
        position: absolute;
        background: rgba(0, 0, 0, 0.7);
        overflow: hidden;
        transition: 0.5s;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;
        width:859.9px;
        height: 500px;
        @media screen and (max-width: 1540px) {
            height:400px;
            width: 687.92px;
            max-width: 100%;
        }
    }
    .video {
        position: absolute;
        left: 0;
        top: 0;
        width: 859.9px;
        height: 500px;
        transition: 0.5s;
        @media screen and (max-width: 1540px) {
            height: 400px;
            width: 687.92px;
            max-width: 100%;

        }
    }
</style>
